import { TicketCheckinUploadStatus } from '@/enums/tickets';
import { EventStorage } from '@/persistence/event';
import { ok } from '@/result';
import { CheckinResult, TicketCheckin } from '@/types/checkIn';
import { ErrorTypes } from '@/types/error';
import { ScanCode, TicketCheckinValidityIntervalWithTimestamps, TicketCode } from '@/types/ticket';
import { TICKET_CHECKIN_SUCCESS } from '@/utils/consts';
import { getCurrentTimestampInSeconds } from '@/utils/date';
import { getErrorMessageTranslationKey } from '@/utils/errorMessages';
import { getStorageWrapper } from '@/utils/storageWrapper';
import { useCallback } from 'react';

export const useTicketCheckin = () => {
  return useCallback(
    async (scanCode: ScanCode | undefined, ticketCode: TicketCode | undefined): Promise<CheckinResult> => {
      if (ticketCode === undefined || scanCode === undefined) {
        return { reason: ErrorTypes.TICKET_NOT_FOUND, success: false, ticket: null, ticketCheckin: undefined };
      }

      const eventStorage = getStorageWrapper();
      const currentTimestampInSec = getCurrentTimestampInSeconds();
      const ticketCheckin = {
        checkin: currentTimestampInSec,
        result: TICKET_CHECKIN_SUCCESS,
        scanCode,
        ticketCode,
        uploadStatus: TicketCheckinUploadStatus.UNPUSHED,
      };

      const storageTicket = await eventStorage.findTicket(scanCode, ticketCode);
      if (!storageTicket.ok || storageTicket.data === undefined) {
        ticketCheckin.result = ErrorTypes.TICKET_NOT_FOUND;

        await saveTicketCheckinToStorage(ticketCheckin, eventStorage);
        return { reason: ErrorTypes.TICKET_NOT_FOUND, success: false, ticket: null, ticketCheckin: ticketCheckin };
      }

      const { data } = storageTicket;
      if (data.checkin !== null) {
        ticketCheckin.result = ErrorTypes.TICKET_ALREADY_CHECKEDIN;

        await saveTicketCheckinToStorage(ticketCheckin, eventStorage);
        return {
          reason: ErrorTypes.TICKET_ALREADY_CHECKEDIN,
          success: false,
          ticket: data,
          ticketCheckin: ticketCheckin,
        };
      }

      if (data.isRevoked) {
        ticketCheckin.result = ErrorTypes.TICKET_REVOKED;

        await saveTicketCheckinToStorage(ticketCheckin, eventStorage);
        return { reason: ErrorTypes.TICKET_REVOKED, success: false, ticket: data, ticketCheckin: ticketCheckin };
      }

      if (data.validityIntervals.length > 0) {
        const ticketIsValid = isTicketValidToCheckin(data.validityIntervals, currentTimestampInSec);
        if (!ticketIsValid) {
          ticketCheckin.result = ErrorTypes.TICKET_NOT_VALID_AT_TIME;

          await saveTicketCheckinToStorage(ticketCheckin, eventStorage);
          return {
            reason: ErrorTypes.TICKET_NOT_VALID_AT_TIME,
            success: false,
            ticket: data,
            ticketCheckin: ticketCheckin,
          };
        }
      }

      const ticketToSave = { ...data, checkin: currentTimestampInSec };
      const saveResult = await eventStorage.saveTicket([ticketToSave]);
      if (!saveResult.ok) {
        const errorTranslationKey = getErrorMessageTranslationKey(saveResult.error);
        ticketCheckin.result = errorTranslationKey;

        await saveTicketCheckinToStorage(ticketCheckin, eventStorage);
        return { reason: errorTranslationKey, success: false, ticket: data, ticketCheckin: ticketCheckin };
      }

      const result = await saveTicketCheckinToStorage(ticketCheckin, eventStorage);
      if (!result.ok) {
        return {
          reason: getErrorMessageTranslationKey(result.error),
          success: false,
          ticket: data,
          ticketCheckin: undefined,
        };
      }

      return { success: true, ticket: data, ticketCheckin: ticketCheckin };
    },
    [],
  );
};

const saveTicketCheckinToStorage = async (ticketCheckin: TicketCheckin, eventStorage: EventStorage) => {
  const result = await eventStorage.saveTicketCheckin(ticketCheckin);
  if (!result.ok) {
    return result;
  }
  return ok(undefined);
};

const isTicketValidToCheckin = (
  validityIntervals: TicketCheckinValidityIntervalWithTimestamps[],
  currentTimestampInSec: number,
) => {
  return validityIntervals.some((interval) => isTimestampInInterval(interval, currentTimestampInSec));
};

const isTimestampInInterval = (
  interval: TicketCheckinValidityIntervalWithTimestamps,
  currentTimestampInSec: number,
) => {
  return interval.startTimestampInSec <= currentTimestampInSec && interval.endTimestampInSec >= currentTimestampInSec;
};
