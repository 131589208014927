import { TicketCode } from '@/types/ticket';

/**
 * expected formats: code OR comment || code
 *
 * regex with named capture groups to only include the code part in the results
 */
const COMMENT_CODE_PATTERN = /^((?<comment>.*)(?<sep>\|\|\s*))?(?<code>.+)$/;

export const stripCommentFromTicketCode = (code: TicketCode): string => {
  const matches = code.match(COMMENT_CODE_PATTERN);

  if (matches === null || matches['groups'] === undefined) {
    return code.trim();
  }

  if (matches['groups']['code'] === undefined) {
    return code.trim();
  }

  return matches['groups']['code'].trim();
};
