import { ScanCodeErrorContext } from '@/context/ScanCodeErrorContext';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';

export const useRedirectOnError = () => {
  const router = useRouter();
  const { scanCodeError } = useContext(ScanCodeErrorContext);
  useEffect(() => {
    if (scanCodeError !== undefined) {
      router.push('/');
    }
  }, [scanCodeError, router]);
};
