import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import styles from '@/components/Header/Header.module.css';
import { ReactNode } from 'react';
import Link from 'next/link';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface HeaderProps {
  title?: ReactNode;
  backUrl?: string;
  actions?: ReactNode;
  className?: string;
}

export const Header = ({ actions, title, backUrl, className }: HeaderProps) => (
  <AppBar position="static" className={[styles.root, className].join(' ')}>
    <Toolbar variant="dense">
      {backUrl === undefined ? undefined : (
        <IconButton component={Link} href={backUrl} edge="start" aria-label="back" sx={{ mr: 2 }} color="inherit">
          <ArrowBackIcon fontSize="medium" />
        </IconButton>
      )}
      {title === undefined ? undefined : (
        <Typography variant="h6" color="inherit" noWrap className={styles.title}>
          {title}
        </Typography>
      )}
      {actions === undefined ? undefined : <div className={styles.buttonWrapper}>{actions}</div>}
    </Toolbar>
  </AppBar>
);
