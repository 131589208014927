import { Footer } from '@/components/Footer';
import { FullscreenButton } from '@/components/FullscreenButton';
import { Header } from '@/components/Header';
import styles from '@/components/Layout/Layout.module.css';
import { ScanCode } from '@/types/ticket';
import { ReactNode } from 'react';

interface LayoutProps {
  children: ReactNode;
  title?: string;
  scanCode?: ScanCode;
}

export const InAppLayout = ({ children, title, scanCode }: LayoutProps) => {
  return (
    <div className={[styles.root, styles.rootWitHeader].join(' ')}>
      <Header title={title} backUrl="/" className={styles.header} actions={<FullscreenButton />} />
      <main className={styles.content}>{children}</main>
      <Footer className={styles.status} scanCode={scanCode} />
    </div>
  );
};
