import { Message, useMessage } from '@/i18n/Message';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const HistoryItems = ({ children }: Props) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label={useMessage('history-table.table-aria-label')}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <Message id="history-table.code-title" />
            </TableCell>
            <TableCell align="right">
              <Message id="history-table.checkin-title" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};
