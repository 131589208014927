import { useCallback, useEffect, useMemo, useState } from 'react';

export const useFullscreen = () => {
  const [isFullscreen, setIsFullscreen] = useState(checkFullscreenEnabled());
  const [isFullScreenSupported, setIsFullScreenSupported] = useState(false);

  const toggleFullscreen = useCallback(() => {
    if (!isFullScreenSupported) {
      return;
    }
    if (isFullscreen) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }, [isFullscreen, isFullScreenSupported]);

  useEffect(() => {
    setIsFullScreenSupported(
      document.fullscreenEnabled || typeof document.documentElement.requestFullscreen !== 'undefined',
    );
  }, []);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!(document.fullscreenElement === null));
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  return useMemo(
    () => ({
      isFullScreenSupported,
      isFullscreen,
      toggleFullscreen,
    }),
    [isFullScreenSupported, isFullscreen, toggleFullscreen],
  );
};

const checkFullscreenEnabled = () => {
  if (typeof document === 'undefined') {
    return false;
  }
  return document.fullscreenElement !== null;
};
