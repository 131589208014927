import { AppStateContext } from '@/context/AppStateContext';
import { useHandleErrors } from '@/hooks/useHandleErrors';
import { useInitialTicketDownload } from '@/hooks/useInitialTicketDownload';
import { useLoadScanCodeEventData } from '@/hooks/useLoadScanCodeEventData';
import { LocalEventStorage } from '@/persistence/localeEventStorage';
import { noop } from '@/utils/helper';
import { useContext, useEffect } from 'react';

export const useLoadEventAndTicketData = () => {
  const { reportErrorAndShowFatalErrorPage } = useHandleErrors();
  const { setEventName, scanCode } = useContext(AppStateContext);
  const { loadEventData, scanCodeDataLoadFinished } = useLoadScanCodeEventData();
  const { initialDownloadDone, checkIfInitialDownloadIsDoneAndReadyToScan, isReadyToScan } = useInitialTicketDownload();

  useEffect(() => {
    if (scanCodeDataLoadFinished || initialDownloadDone || scanCode === undefined || scanCode === '') {
      return;
    }
    loadEventData(scanCode).then(noop);
  }, [initialDownloadDone, loadEventData, scanCodeDataLoadFinished, scanCode]);

  useEffect(() => {
    if (!scanCodeDataLoadFinished || scanCode === undefined || scanCode === '') {
      return;
    }

    const response = LocalEventStorage.getEventNameByScanCode(scanCode);

    if (!response.ok) {
      reportErrorAndShowFatalErrorPage(response.error, ['useLoadEventAndTicketData']);
      return;
    }

    setEventName(response.data);
    checkIfInitialDownloadIsDoneAndReadyToScan();
  }, [
    checkIfInitialDownloadIsDoneAndReadyToScan,
    reportErrorAndShowFatalErrorPage,
    scanCode,
    scanCodeDataLoadFinished,
    setEventName,
  ]);

  return {
    initialDownloadDone,
    isReadyToScan,
    scanCodeDataLoadFinished,
  };
};
