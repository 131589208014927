import styles from '@/library/Panel/Panel.module.css';
import { Paper } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const Panel = ({ children }: Props) => {
  return <Paper className={styles.root}>{children}</Paper>;
};
