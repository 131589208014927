import { noop } from '@/utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo } from 'react';

interface StorageValueOptions {
  defaultValue?: string;
  /**
   * When set to a number, refetches the query every N milliseconds.
   */
  refetchInterval?: number | false;
}

const useStorageValue = (storage: Storage, key: string, options: StorageValueOptions = {}) => {
  const { data: value } = useQuery({
    queryFn: () => {
      const value = storage.getItem(key);
      return value === null ? options.defaultValue : value;
    },
    queryKey: ['storage-value', storage, key, options.defaultValue],
    refetchInterval: options.refetchInterval,
  });
  const { mutate: clearFromStorage } = useMutation({
    mutationFn: async ({ storage, key }: { storage: Storage; key: string }) => {
      storage.removeItem(key);
    },
  });
  const { mutate: setStorageValue } = useMutation({
    mutationFn: async ({ storage, key, value }: { storage: Storage; key: string; value: string }) => {
      storage.setItem(key, value);
    },
  });
  const clear = useCallback(() => {
    clearFromStorage({ storage, key });
  }, [clearFromStorage, storage, key]);
  const set = useCallback(
    (value: string) => {
      setStorageValue({ key, storage, value });
    },
    [setStorageValue, storage, key],
  );
  const queryClient = useQueryClient();
  useEffect(() => {
    const handleStorage = (e: StorageEvent) => {
      if (e.storageArea !== storage) {
        return;
      }
      if (e.key !== key) {
        return;
      }
      queryClient.invalidateQueries({ queryKey: ['storage-value', storage, key] }).then(noop);
    };
    window.addEventListener('storage', handleStorage);
    return () => {
      window.removeEventListener('storage', handleStorage);
    };
  });
  return useMemo(() => ({ clear, set, value }), [value, set, clear]);
};

export const useLocalStorageValue = (key: string, options: StorageValueOptions = {}) =>
  useStorageValue(window.localStorage, key, options);
