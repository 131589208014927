import { ComponentType, ReactNode, useId, useRef, useState } from 'react';
import { Button, ButtonProps, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { noop } from '@/utils';
import { useMessage } from '@/i18n/Message';

interface Input {
  name: ReactNode;
  Icon: ComponentType;
}

interface InputPickerProps<T extends Input> {
  options: ReadonlyArray<T>;
  value: T;
  onChange?: (value: T) => void;
  className?: string;
  color?: ButtonProps['color'];
}

export const InputPicker = <T extends Input>({
  options,
  value,
  onChange = noop,
  className,
  color,
}: InputPickerProps<T>) => {
  const toggleRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const createMenuItemClickHandler = (input: T) => () => {
    onChange(input);
    setOpen(false);
  };
  const menuId = useId();
  return (
    <>
      <Button
        startIcon={<value.Icon />}
        endIcon={<ArrowDropDownIcon />}
        ref={toggleRef}
        onClick={() => setOpen((prev) => !prev)}
        className={className}
        color={color}
        aria-controls={open ? menuId : undefined}
        aria-haspopup="listbox"
        aria-expanded={open ? 'true' : undefined}
        aria-label={useMessage('input-type')}
        role="combobox"
      >
        {value.name}
      </Button>
      <Menu anchorEl={toggleRef.current} open={open} onClose={() => setOpen(false)} id={menuId} role="listbox">
        {options.map((input, index) => (
          <MenuItem key={index} onClick={createMenuItemClickHandler(input)} selected={input === value} role="option">
            <ListItemIcon>
              <input.Icon />
            </ListItemIcon>
            <ListItemText>{input.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
