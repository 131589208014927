import styles from '@/components/CheckinHistory/HistoryItem.module.css';
import { CheckinResultIcon } from '@/library/CheckinResultIcon';
import { DateTime } from '@/library/DateTime';
import { TicketCheckin } from '@/types/checkIn';
import { TICKET_CHECKIN_SUCCESS } from '@/utils/consts';
import { dateFromSeconds } from '@/utils/date';
import { TableCell, TableRow } from '@mui/material';
import { memo } from 'react';

type Props = Pick<TicketCheckin, 'result' | 'ticketCode' | 'checkin'>;

export const HistoryItem = memo((item: Props) => {
  return (
    <TableRow className={item.result === TICKET_CHECKIN_SUCCESS ? styles.success : styles.error}>
      <TableCell component="th" scope="row" padding="none">
        <CheckinResultIcon ticketCheckin={item} className={styles.icon} />
      </TableCell>
      <TableCell>{item.ticketCode}</TableCell>
      <TableCell align="right">
        <DateTime value={dateFromSeconds(item.checkin)} dateStyle="long" timeStyle="medium" />
      </TableCell>
    </TableRow>
  );
});
