import { useFullscreen } from '@/hooks/useFullscreen';
import Fullscreen from '@mui/icons-material/Fullscreen';
import FullscreenExit from '@mui/icons-material/FullscreenExit';
import { IconButton } from '@mui/material';

export const FullscreenButton = () => {
  const { isFullScreenSupported, isFullscreen, toggleFullscreen } = useFullscreen();

  if (!isFullScreenSupported) {
    return null;
  }

  return (
    <IconButton onClick={toggleFullscreen} aria-label="button-fullscreen" color="inherit">
      {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
    </IconButton>
  );
};
