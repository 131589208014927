import { ComponentProps } from 'react';
import { FormattedDate, FormattedDateTimeRange } from 'react-intl';

interface DateTimeCommonProps {
  dateStyle?: ComponentProps<typeof FormattedDate>['dateStyle'];
  timeStyle?: ComponentProps<typeof FormattedDate>['timeStyle'];
}

type DateTimeProps = {
  value: Date;
} & DateTimeCommonProps;

export const DateTime = ({ timeStyle = 'medium', ...props }: DateTimeProps) => {
  // Remove hour12={false} when we allow locale selection
  return <FormattedDate {...props} timeStyle={timeStyle} hour12={false} />;
};

type DateTimePeriodProps = {
  from: Date;
  to: Date;
} & DateTimeCommonProps;

export const DateTimePeriod = ({ timeStyle = 'medium', dateStyle = 'long', ...props }: DateTimePeriodProps) => {
  return <FormattedDateTimeRange timeStyle={timeStyle} dateStyle={dateStyle} hour12={false} {...props} />;
};
