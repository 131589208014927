import styles from '@/components/Checkin/Checkin.module.css';
import { TicketScanView } from '@/components/TicketScanView';
import { AppStateContext } from '@/context/AppStateContext';
import { useLoadEventAndTicketData } from '@/hooks/useLoadEventAndTicketData';
import { useRedirectOnError } from '@/hooks/useRedirectOnError';
import { translate } from '@/i18n';
import { Loading } from '@/library/Loading';
import { Typography } from '@mui/material';
import { useContext } from 'react';

interface CheckInProps {
  className?: string;
}

export const CheckIn = ({ className }: CheckInProps) => {
  const { scanCode } = useContext(AppStateContext);
  const { scanCodeDataLoadFinished, initialDownloadDone, isReadyToScan } = useLoadEventAndTicketData();

  useRedirectOnError();

  const showTickeScanView = initialDownloadDone && isReadyToScan && scanCode !== undefined && scanCode.length > 0;
  const showNoTicketsAvailable = initialDownloadDone && !isReadyToScan;

  if (!scanCodeDataLoadFinished) {
    return <Loading text={translate('scanTicket', 'loadingEventData')} />;
  }

  return (
    <div className={[styles.root, className].join(' ')}>
      {showNoTicketsAvailable ? (
        <div className={styles.noTicketsInfo}>
          <Typography variant="h5">{translate('scanTicket', 'noTicketsAvailable')}</Typography>
          <p>{translate('scanTicket', 'noTicketsHint')}</p>
        </div>
      ) : null}
      {initialDownloadDone ? null : <Loading text={translate('scanTicket', 'loading')} />}
      {showTickeScanView ? <TicketScanView scanCode={scanCode} /> : null}
    </div>
  );
};
