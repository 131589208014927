import { Dialog, DialogTitle, IconButton, useMediaQuery } from '@mui/material';
import { ReactNode } from 'react';
import { Message, useMessage } from '@/i18n/Message';
import { clientConfig } from '@/config/client.config';
import styles from './HistoryModal.module.css';
import CloseIcon from '@mui/icons-material/Close';

interface HistoryModalProps {
  open?: boolean;
  onClose?: () => void;
  children?: ReactNode;
}

export const HistoryModal = ({ open = true, onClose, children }: HistoryModalProps) => {
  const closeButtonAriaLabel = useMessage('history-table.close-history-label');
  const smallScreen = useMediaQuery('(max-width: 700px)');
  return (
    <Dialog open={open} onClose={onClose} keepMounted={false} fullScreen={smallScreen} maxWidth="sm">
      <DialogTitle className={styles.title}>
        <Message id="history-table.headline" /> (
        <Message id="history-table.max-attempts" values={{ maxEntries: clientConfig.checkinHistory.maxEntries }} />)
        <IconButton size="small" onClick={onClose} aria-label={closeButtonAriaLabel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <div className={styles.content}>{children}</div>
    </Dialog>
  );
};
