import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { WORKER_MESSAGE_PERIODIC_DOWNLOAD_DONE } from '@/worker/consts';
import { useTicketCount } from '@/hooks/useTicketCount';
import { AppStateContext } from '@/context/AppStateContext';

export const useInitialTicketDownload = () => {
  const { scanCode } = useContext(AppStateContext);
  const [initialDownloadDone, setInitialDownloadDone] = useState(false);
  const [isReadyToScan, setIsReadyToScan] = useState(false);
  const { ticketCount, getLocalStorageCount } = useTicketCount();

  const checkIfInitialDownloadIsDoneAndReadyToScan = useCallback(() => {
    if (scanCode === undefined || scanCode === '') {
      return;
    }

    if (ticketCount > 0) {
      setInitialDownloadDone(true);
      setIsReadyToScan(true);
      return;
    }

    if (ticketCount === 0 && initialDownloadDone) {
      setIsReadyToScan(false);
      return;
    }
  }, [ticketCount, scanCode, initialDownloadDone]);

  useEffect(() => {
    // function to handle the message send by the useTicketDownloader-Worker
    function handleMessage(event: { origin: string; data: { type: string; scanCode: string } }) {
      if (event.origin !== window.location.origin) {
        return;
      }
      if (event.data.type === WORKER_MESSAGE_PERIODIC_DOWNLOAD_DONE) {
        setInitialDownloadDone(true);
        getLocalStorageCount(event.data.scanCode).then((count: number) => {
          if (count > 0) {
            setIsReadyToScan(true);
            // remove the event listener after we received the first tickets - we don't need it anymore
            window.removeEventListener('message', handleMessage);
          }
        });
      }
    }
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [initialDownloadDone, getLocalStorageCount, ticketCount]);

  return useMemo(
    () => ({
      checkIfInitialDownloadIsDoneAndReadyToScan,
      initialDownloadDone,
      isReadyToScan,
    }),
    [checkIfInitialDownloadIsDoneAndReadyToScan, isReadyToScan, initialDownloadDone],
  );
};
