import { useId } from 'react';
import { useDevice, useDevicePool, usePause, useScanner } from '@/components/Scanner/logic';
import { noop } from '@/utils';
import styles from './Scanner.module.css';
import clsx from 'clsx';
import { Fab } from '@mui/material';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';

interface ScannerProps {
  onScan?: (code: string) => void;
  disabled?: boolean;
  className?: string;
}

export const Scanner = ({ onScan = noop, disabled = false, className }: ScannerProps) => {
  const id = useId();
  const numberOfDevices = useDevicePool()?.length ?? 0;
  const { device, next } = useDevice();
  const scanner = useScanner(id, device?.id, onScan);
  usePause(scanner, disabled);
  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.viewFinder} id={id} />
      {numberOfDevices > 1 ? (
        <Fab onClick={next} className={styles.deviceSelector} color="default">
          <CameraswitchIcon />
        </Fab>
      ) : undefined}
    </div>
  );
};
