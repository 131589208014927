import { CheckIn } from '@/components/Checkin';
import { FatalErrorFallbackUI } from '@/components/FatalErrorFallbackUI';
import { InAppLayout } from '@/components/Layout/InAppLayout';
import styles from '@/components/Layout/Layout.module.css';
import { AppStateContext } from '@/context/AppStateContext';
import { useContext } from 'react';

export default function InAppView() {
  const { showBlockingErrorPage, eventName, scanCode } = useContext(AppStateContext);

  return (
    <>
      {showBlockingErrorPage ? (
        <FatalErrorFallbackUI />
      ) : (
        <InAppLayout title={eventName} scanCode={scanCode}>
          <CheckIn className={styles.fullWidthAndHeight} />
        </InAppLayout>
      )}
    </>
  );
}
