import { Message, useMessage } from '@/i18n/Message';
import HistoryIcon from '@mui/icons-material/History';
import { Button } from '@mui/material';

interface CheckinHistoryButtonProps {
  onClick?: () => void;
  className?: string;
}

export const CheckinHistoryButton = ({ onClick, className }: CheckinHistoryButtonProps) => {
  return (
    <Button
      className={className}
      variant="text"
      onClick={onClick}
      aria-label={useMessage('history-table.open-aria-label')}
      startIcon={<HistoryIcon />}
      color="inherit"
    >
      <Message id="button.checkin-history" />
    </Button>
  );
};
