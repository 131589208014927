import styles from '@/components/TicketCheckinResult/Details.module.css';
import { Message } from '@/i18n/Message';
import { DateTime, DateTimePeriod } from '@/library/DateTime';
import { TicketCheckin } from '@/types/checkIn';
import { Ticket } from '@/types/ticket';
import { dateFromSeconds } from '@/utils/date';
import { ticketAlreadyCheckedIn, ticketNotValidAtTime } from '@/utils/ticketResult';
import { Typography } from '@mui/material';

interface DetailsProps {
  ticketCheckin: TicketCheckin;
  usedTicket: Ticket;
}

export const Details = ({ ticketCheckin, usedTicket }: DetailsProps) => {
  return (
    <section className={styles.root}>
      {ticketAlreadyCheckedIn(ticketCheckin) && usedTicket.checkin !== null ? (
        <>
          <Typography>
            <Message id="ticket-checkin.original-checkin" />
          </Typography>
          <Typography variant="h6" component="time">
            <DateTime value={dateFromSeconds(usedTicket.checkin)} dateStyle="long" timeStyle="medium" />
          </Typography>
        </>
      ) : null}
      {ticketNotValidAtTime(ticketCheckin) && usedTicket.validityIntervals.length > 0 ? (
        <>
          <Typography>
            <Message id="ticket-checkin.valid-periods" />
          </Typography>
          {usedTicket.validityIntervals.map((period) => (
            <Typography variant="h6" component="time" key={period.startTimestampInSec}>
              <DateTimePeriod from={new Date(period.start)} to={new Date(period.end)} />
            </Typography>
          ))}
        </>
      ) : null}
    </section>
  );
};
