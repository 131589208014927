import { CircularProgress } from '@mui/material';
import styles from '@/library/Loading/Loading.module.css';

interface Props {
  text?: string | undefined;
}

export const Loading = ({ text }: Props) => {
  return (
    <div className={styles.container}>
      <CircularProgress />
      {text === undefined ? undefined : <p>{text}</p>}
    </div>
  );
};
