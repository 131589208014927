import { CheckinHistory, HistoryModal } from '@/components/CheckinHistory';
import commonStyles from '@/components/Layout/Layout.module.css';
import { ScanView } from '@/components/TicketScanView/ScanView';
import { useState } from 'react';

interface Props {
  scanCode: string;
}

// as soon as we adjust the view to be able to show the history on the camera screen, we need to adjust the view here - currently we leave the view as it is
export const TicketScanView = ({ scanCode }: Props) => {
  const [showHistory, setShowHistory] = useState(false);

  return (
    <>
      <ScanView
        scanCode={scanCode}
        onHistoryOpen={() => setShowHistory(true)}
        disableInput={showHistory}
        className={commonStyles.fullWidthAndHeight}
      />
      <HistoryModal open={showHistory} onClose={() => setShowHistory(false)}>
        <CheckinHistory />
      </HistoryModal>
    </>
  );
};
