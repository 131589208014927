import { CheckinHistoryButton } from '@/components/CheckinHistory/CheckinHistoryButton';
import { TicketCheckinResult } from '@/components/TicketCheckinResult';
import { CameraInput } from '@/components/TicketScanInput/CameraInput';
import HardwareInput from '@/components/TicketScanInput/HardwareInput';
import { InputPicker } from '@/components/TicketScanInput/InputPicker';
import { TextFieldInput } from '@/components/TicketScanInput/TextFieldInput';
import styles from '@/components/TicketScanView/ScanView.module.css';
import { CameraDataContext } from '@/context/CameraDataProvider';
import { useTicketCheckin } from '@/hooks/useTicketCheckin';
import { Message } from '@/i18n/Message';
import { useSetting } from '@/settings';
import { CheckinResult, TicketCheckin } from '@/types/checkIn';
import { Ticket } from '@/types/ticket';
import { noop } from '@/utils/helper';
import { stripCommentFromTicketCode } from '@/utils/ticketCode';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import SensorsIcon from '@mui/icons-material/Sensors';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext, useRef, useState } from 'react';

const INPUTS = {
  'text-field': {
    Component: TextFieldInput,
    Icon: KeyboardIcon,
    isSupported: () => true,
    name: <Message id="input-type.text-field" />,
  },
  // eslint-disable-next-line sort-keys
  'camera': {
    Component: CameraInput,
    Icon: CameraAltIcon,
    isSupported: ({ hasCamera }: { hasCamera: boolean }) => hasCamera,
    name: <Message id="input-type.camera" />,
  },
  'hardware': {
    Component: HardwareInput,
    Icon: SensorsIcon,
    isSupported: () => true,
    name: <Message id="input-type.hardware" />,
  },
} as const;

type Inputs = typeof INPUTS;
type InputKey = keyof Inputs;

const getKey = <T extends Inputs[InputKey]>(input: T) => {
  return (Object.keys(INPUTS) as InputKey[]).find((key) => INPUTS[key] === input) as InputKey;
};

interface Props {
  scanCode: string;
  onHistoryOpen?: () => void;
  className?: string;
  disableInput?: boolean;
}

export const ScanView = ({ scanCode, onHistoryOpen, className, disableInput = false }: Props) => {
  const validateCheckIn = useTicketCheckin();
  const { cameraSupported } = useContext(CameraDataContext);
  const [checkInResult, setCheckInResult] = useState<TicketCheckin>();
  const [usedTicket, setUsedTicket] = useState<Ticket>();
  const resumeScanning = useRef<() => void>(noop);
  const [inputKey, setInput] = useSetting('input-mode');
  const queryClient = useQueryClient();

  const onResultDismiss = useCallback(async () => {
    setCheckInResult(undefined);
    // resolve the promise from validateTicketCheckin
    resumeScanning.current();
  }, []);

  const validateTicketCheckin = useCallback(
    async (givenTicketCode: string) => {
      const ticketCode = stripCommentFromTicketCode(givenTicketCode);
      const result = await validateCheckIn(scanCode, ticketCode);
      setCheckInResult(result.ticketCheckin);

      setUsedTicket(setTicketOrEmptyTicketOnNotFoundError(result, scanCode, ticketCode));
      await queryClient.invalidateQueries({
        queryKey: ['checkin-attempts', scanCode],
      });
      await queryClient.invalidateQueries({
        queryKey: ['checked-in-tickets', scanCode],
      });

      return result.success;
    },
    [scanCode, validateCheckIn, queryClient],
  );

  const input = INPUTS[inputKey];
  return (
    <div className={[styles.root, className].join(' ')}>
      <InputPicker
        options={Object.values(INPUTS).filter((input) => input.isSupported({ hasCamera: cameraSupported }))}
        value={input}
        onChange={(input) => setInput(getKey(input))}
        className={styles.picker}
        color="inherit"
      />
      <CheckinHistoryButton onClick={onHistoryOpen} className={styles.checkinHistoryButton} />
      <input.Component
        disabled={checkInResult !== undefined || disableInput}
        onSubmit={validateTicketCheckin}
        className={styles.input}
      />
      {checkInResult === undefined ? undefined : (
        <TicketCheckinResult
          ticketCheckin={checkInResult}
          usedTicket={usedTicket ?? undefined}
          onClose={onResultDismiss}
          className={styles.result}
        />
      )}
    </div>
  );
};

const setTicketOrEmptyTicketOnNotFoundError = (result: CheckinResult, scanCode: string, ticketCode: string) => {
  // we need an empty ticket to show - on error - the ticketCode in the resultView - should only happen if the ticket is not found
  return (
    result.ticket ?? {
      checkin: null,
      color: null,
      info: null,
      isRevoked: false,
      scanCode: scanCode,
      ticketCode: ticketCode,
      typeLocale: 'de',
      typeText: '',
      validityIntervals: [],
    }
  );
};
