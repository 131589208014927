import { TicketCheckin } from '@/types/checkIn';
import { ErrorTypes } from '@/types/error';
import { TICKET_CHECKIN_SUCCESS } from './consts';

export const ticketNotFound = (ticketCheckin: Pick<TicketCheckin, 'result'>): boolean => {
  return ticketCheckin.result === ErrorTypes.TICKET_NOT_FOUND;
};
export const ticketAlreadyCheckedIn = (ticketCheckin: Pick<TicketCheckin, 'result'>): boolean => {
  return ticketCheckin.result === ErrorTypes.TICKET_ALREADY_CHECKEDIN;
};
export const ticketRevoked = (ticketCheckin: Pick<TicketCheckin, 'result'>): boolean => {
  return ticketCheckin.result === ErrorTypes.TICKET_REVOKED;
};
export const ticketNotValidAtTime = (ticketCheckin: Pick<TicketCheckin, 'result'>): boolean => {
  return ticketCheckin.result === ErrorTypes.TICKET_NOT_VALID_AT_TIME;
};
export const ticketCheckinSuccessful = (ticketCheckin: Pick<TicketCheckin, 'result'>): boolean => {
  return ticketCheckin.result === TICKET_CHECKIN_SUCCESS;
};
