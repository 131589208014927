import styles from '@/components/TicketCheckinResult/TicketCheckinResult.module.css';
import { clientConfig } from '@/config/client.config';
import { Message, mapServerErrorToMessageId, useMessage } from '@/i18n/Message';
import { CheckinResultIcon } from '@/library/CheckinResultIcon';
import { TicketCheckin } from '@/types/checkIn';
import { Ticket } from '@/types/ticket';
import { TICKET_CHECKIN_SUCCESS } from '@/utils/consts';
import { ticketAlreadyCheckedIn, ticketNotFound, ticketNotValidAtTime } from '@/utils/ticketResult';
import { Button, Collapse, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Details } from './Details';

interface TicketCheckinResultProps {
  ticketCheckin: TicketCheckin;
  usedTicket?: Ticket;
  onClose?: () => void;
  className?: string;
}

const getBackgroundColor = (usedTicket: Ticket | undefined) => {
  if (usedTicket !== undefined && usedTicket.color !== null) {
    return styles[`success-${usedTicket.color.toLowerCase()}`];
  }
  return styles.success;
};

export const TicketCheckinResult = ({ ticketCheckin, onClose, usedTicket, className }: TicketCheckinResultProps) => {
  const success = ticketCheckin.result === TICKET_CHECKIN_SUCCESS;
  const classes = [styles.root, success ? getBackgroundColor(usedTicket) : styles.failure, className];
  const audioSrc = success ? '/assets/sounds/beep.mp3' : '/assets/sounds/alert.mp3';
  const messageId = success ? 'ticket-checkin.success' : mapServerErrorToMessageId(ticketCheckin.result);
  const audioText = useMessage(messageId);
  // TODO at revoked we don't have info yet when the ticket was canceled - eg to show it in FE - clarify if we want to have it
  // we need no further Infos if the ticket was not found - so we hide the info section
  const showTicketInfo = ticketNotFound(ticketCheckin) ? false : true;
  const [showDetails, setShowDetails] = useState(false);

  const detailsAvailable = (() => {
    if (success || usedTicket === undefined) {
      return false;
    }

    if (
      (ticketAlreadyCheckedIn(ticketCheckin) && usedTicket.checkin !== null) ||
      (ticketNotValidAtTime(ticketCheckin) && usedTicket.validityIntervals.length > 0)
    ) {
      return true;
    }

    return false;
  })();

  useEffect(() => {
    if (!success) {
      return;
    }
    const timer = setTimeout(() => {
      onClose?.();
    }, clientConfig.autoHideCheckinResultTimeout);

    return () => clearTimeout(timer);
  }, [success, onClose]);

  const onDetailsButtonClick = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    setShowDetails(!showDetails);
  };

  /* div used as clickable elment on purpose - we are currently using a button to display ticket details on this screen - nesting a button within another button is no valid HTML.  */
  return (
    <div onClick={onClose} role="button" tabIndex={0} onKeyPress={onClose} className={classes.join(' ')}>
      <div className={styles.visualSection}>
        <Typography variant="h1" component="div">
          <CheckinResultIcon ticketCheckin={ticketCheckin} size="inherit" />
        </Typography>
        <Typography variant="h4" className={styles.headline} component="h1">
          <Message id={messageId} />
        </Typography>
        {usedTicket === undefined ? null : (
          <Typography variant="subtitle1" component="p">
            <Message id="ticket-checkin.ticket-id" />: {usedTicket.ticketCode}
          </Typography>
        )}
        {detailsAvailable && usedTicket !== undefined ? (
          <>
            <Button onClick={onDetailsButtonClick} variant="outlined" color="inherit">
              <Message
                id="button.show-hide-more-details"
                values={{
                  action: showDetails ? 'hide' : 'show',
                }}
              />
            </Button>
            <Collapse in={showDetails} timeout="auto" unmountOnExit>
              <Details ticketCheckin={ticketCheckin} usedTicket={usedTicket} />
            </Collapse>
          </>
        ) : null}
      </div>
      <div className={styles.infoSection}>
        <hr className={styles.horizontalLine} />
        {usedTicket === undefined || !showTicketInfo ? null : (
          <Typography variant="h4" component="p" className={styles.ticketInfo}>
            {usedTicket.typeText}
          </Typography>
        )}
        <Typography variant="subtitle1" component="div">
          <Message id="button.close" />
        </Typography>
        <audio aria-label={audioText} src={audioSrc} autoPlay={true}>
          <track kind="captions" src={audioText} />
        </audio>
      </div>
    </div>
  );
};
