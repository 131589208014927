import styles from '@/components/CheckinHistory/CheckinHistory.module.css';
import { useCheckinHistory } from '@/hooks/useCheckinHistory';
import { Message } from '@/i18n/Message';
import { HistoryItem } from './HistoryItem';
import { HistoryItems } from './HistoryItems';

interface CheckinHistoryProps {
  className?: string;
}

export const CheckinHistory = ({ className }: CheckinHistoryProps) => {
  const history = useCheckinHistory();

  return (
    <div className={[styles.root, className].join(' ')}>
      {history.length === 0 ? (
        <Message id="history-table.no-history" />
      ) : (
        <HistoryItems>
          {history.map((item) => (
            <HistoryItem {...item} key={item.checkin} />
          ))}
        </HistoryItems>
      )}
    </div>
  );
};

export * from './HistoryModal';
