import { TicketCheckin } from '@/types/checkIn';
import {
  ticketAlreadyCheckedIn,
  ticketCheckinSuccessful,
  ticketNotFound,
  ticketNotValidAtTime,
  ticketRevoked,
} from '@/utils/ticketResult';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BlockIcon from '@mui/icons-material/Block';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { SvgIconOwnProps } from '@mui/material';

interface CheckinResultIconProps {
  ticketCheckin: Pick<TicketCheckin, 'result'>;
  size?: SvgIconOwnProps['fontSize'];
  className?: string;
}

export const CheckinResultIcon = ({ ticketCheckin, size, className }: CheckinResultIconProps) => {
  const Component = (() => {
    if (ticketAlreadyCheckedIn(ticketCheckin)) {
      return ContentCopyIcon;
    }
    if (ticketRevoked(ticketCheckin)) {
      return BlockIcon;
    }
    if (ticketNotFound(ticketCheckin)) {
      return QuestionMarkIcon;
    }
    if (ticketNotValidAtTime(ticketCheckin)) {
      return AccessTimeIcon;
    }
    if (ticketCheckinSuccessful(ticketCheckin)) {
      return VerifiedUserIcon;
    }
    return QuestionMarkIcon;
  })();
  return <Component fontSize={size} className={className} />;
};
