import { Laser } from '@/components/Laser';
import { append, clear, setCode, useCode } from '@/components/TicketScanInput/HardwareInput/useCode';
import { useGlobalKeyDown } from '@/components/TicketScanInput/HardwareInput/useGlobalKeyDown';
import { usePasteDetection } from '@/components/TicketScanInput/HardwareInput/usePasteDetection';
import { InputProps } from '@/components/TicketScanInput/InputProps';
import { Message, useMessage } from '@/i18n/Message';
import { noop } from '@/utils';
import { Typography } from '@mui/material';
import { useCallback } from 'react';
import styles from './HardwareInput.module.css';

const HardwareInput = ({ disabled = false, onSubmit, className }: InputProps) => {
  const [ticketCode, dispatch] = useCode();

  const handleTicketCodeChange = (event: { target: { value: string } }) => {
    dispatch(setCode(event.target.value));
  };
  const stopPropagation = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
  };

  const handleSubmit = useCallback(
    async (event: { preventDefault: () => void }) => {
      event.preventDefault();
      if (disabled) {
        return;
      }
      const result = await onSubmit(ticketCode);
      if (result) {
        dispatch(clear());
      }
    },
    [onSubmit, ticketCode, dispatch, disabled],
  );

  const handleKeyDown = useCallback(
    async (e: KeyboardEvent) => {
      // e.key is undefined in Chrome on Android when the user picks a suggestion from the virtual keyboard.
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const key = e.key ?? '';
      if (key === 'Enter') {
        await handleSubmit({ preventDefault: noop });
        dispatch(clear());
        return true;
      }
      if (key.length > 1) {
        return true;
      }
      dispatch(append(key));
      return true;
    },
    [handleSubmit, dispatch],
  );
  useGlobalKeyDown(handleKeyDown);

  usePasteDetection(ticketCode, async () => {
    await handleSubmit({ preventDefault: noop });
    dispatch(clear());
  });

  return (
    <form onSubmit={handleSubmit} className={[styles.root, className].join(' ')}>
      {ticketCode === '' ? (
        <div className={styles.empty}>
          <Typography variant="h5">
            <Message id="hardware-input.header" />
          </Typography>
          <Laser className={styles.laser} />
          <Typography variant="body2">
            <Message id="hardware-input.instructions" />
          </Typography>
        </div>
      ) : undefined}
      <input
        onChange={handleTicketCodeChange}
        onKeyDown={stopPropagation}
        value={ticketCode}
        disabled={disabled}
        className={[styles.input, ticketCode === '' ? styles.hidden : ''].join(' ')}
        aria-label={useMessage('hardware-input.text-field.label')}
      />
    </form>
  );
};

export default HardwareInput;
