import { Reducer, useReducer } from 'react';

type State = string;
const DEFAULT_STATE: State = '';

export const append = (character: string) => ({ type: 'APPEND' as const, character });
export const setCode = (code: string) => ({ type: 'SET_CODE' as const, code });
export const clear = () => ({ type: 'CLEAR' as const });

type Action = ReturnType<typeof append> | ReturnType<typeof setCode> | ReturnType<typeof clear>;

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'APPEND': {
      return state + action.character;
    }
    case 'SET_CODE': {
      if (action.code === '') {
        return DEFAULT_STATE;
      }
      return action.code;
    }
    case 'CLEAR':
      return DEFAULT_STATE;
  }
};

export const useCode = () => useReducer(reducer, DEFAULT_STATE);
