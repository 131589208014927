import { InputProps } from '@/components/TicketScanInput/InputProps';
import { translate } from '@/i18n';
import { Panel } from '@/library/Panel';
import SendIcon from '@mui/icons-material/Send';
import { Button, TextField } from '@mui/material';
import { useCallback, useState } from 'react';
import styles from './index.module.css';

export const TextFieldInput = ({ disabled = false, onSubmit, className }: InputProps) => {
  const [ticketCode, setTicketCode] = useState('');
  const isButtonEnabled = ticketCode.trim() !== '' && !disabled;

  const handleTicketCodeChange = useCallback((event: { target: { value: string } }) => {
    setTicketCode(event.target.value);
  }, []);

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (typeof onSubmit !== 'function') {
      return;
    }

    const result = await onSubmit(ticketCode);
    if (result) {
      setTicketCode('');
    }
  };

  return (
    <div className={[styles.root, className].join(' ')}>
      <Panel>
        <form onSubmit={handleSubmit}>
          <TextField
            id="scan_ticket_input"
            label={translate('scanTicket', 'inputLabel')}
            variant="outlined"
            onChange={handleTicketCodeChange}
            value={ticketCode}
            disabled={disabled}
            inputProps={{ autoCapitalize: 'characters' }}
          />
          <Button variant="contained" type="submit" disabled={!isButtonEnabled} endIcon={<SendIcon />}>
            {translate('scanTicket', 'submitButton')}
          </Button>
        </form>
      </Panel>
    </div>
  );
};
