import { useEffect, useRef } from 'react';

const MIN_CHARS = 4;
const MIN_MS_SINCE_LAST_CHAR = 100;
const MAX_MS_PER_CHAR = 50;

/**
 * This hook detects if the code got filled by a machine. This might include a
 * barcode scanner, a paste action or a virtual keyboard.
 */
export const usePasteDetection = (value: string, onPaste: () => void) => {
  const lastValue = useRef('');
  const firstChar = useRef<number>();
  const lastChar = useRef<number>();
  useEffect(() => {
    if (value === '') {
      firstChar.current = undefined;
      lastChar.current = undefined;
      lastValue.current = '';
      return;
    }
    if (value === lastValue.current) {
      return;
    }
    lastChar.current = Date.now();
    if (lastValue.current === '') {
      firstChar.current = Date.now();
    }
    lastValue.current = value;
    const check = () => {
      if (firstChar.current === undefined || lastChar.current === undefined) {
        return;
      }
      if (value.length < MIN_CHARS) {
        return;
      }
      const msPerChar = (lastChar.current - firstChar.current) / value.length;
      if (msPerChar > MAX_MS_PER_CHAR) {
        return;
      }
      onPaste();
    };
    const timeout = window.setTimeout(check, MIN_MS_SINCE_LAST_CHAR);
    return () => {
      window.clearTimeout(timeout);
    };
  }, [value, onPaste]);
};
