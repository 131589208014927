import { clientConfig } from '@/config/client.config';
import { AppStateContext } from '@/context/AppStateContext';
import { getStorageWrapper } from '@/utils/storageWrapper';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

export const useCheckinHistory = () => {
  const { scanCode } = useContext(AppStateContext);

  const { data: history } = useQuery({
    queryKey: ['checkin-attempts', scanCode],
    queryFn: async () => {
      if (scanCode === undefined) {
        return null;
      }
      const result = await getStorageWrapper().getLastCheckinAttempts(scanCode, clientConfig.checkinHistory.maxEntries);
      if (!result.ok) {
        console.warn('error while fetching checkin attempts :: ', result.error);
        return null;
      }

      return result.data;
    },
  });

  return history ?? [];
};
